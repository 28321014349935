import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import qs from "query-string";

import { Input, PasswordInput } from "@ui-kit/InputFields";
import Dialog from "@ui-kit/Dialog";
import { useCss } from "./styles";
import { SnackbarContentWrapper } from "@ui-kit/Notification";
import { ReactGAEventWait } from "@helpers/ga";
import { ROUTES } from "@constants";
import Button from "@ui-kit/Button";
import { useUserStore } from "@store/UserStore";
import { required, email } from "@validators";
import { Models } from "@services/api";
import { useNotificationStore } from "@store/NotificationStore";
import { useAppStore } from "@store/AppStore";
import { AxiosError } from "axios";
import { ServerError } from "@models/common";
import Typography from "@ui-kit/Typography";
import { ShadowDivider } from "@components/ShadowDivider";
import { Spacer } from "@ui-kit/Spacer";

type FormFields = {
  email: string;
  password: string;
};

type ViewVariant = "general" | "auth_required" | "auth_required_warning";

const isLoginView = (view: string): view is ViewVariant => {
  return (
    view === "general" ||
    view === "auth_required" ||
    view === "auth_required_warning"
  );
};

const LoginForm: React.FC = () => {
  const [{ realLocation }] = useAppStore();
  const [{ user }, { login }] = useUserStore();
  const [, { setUnknownErrorNotification }] = useNotificationStore();
  const { handleSubmit, control, errors, formState, watch } =
    useForm<FormFields>();
  const css = useCss();
  const history = useHistory();
  const { search } = useLocation();
  const [errorMessage, setErrorMessage] = useState<React.ReactChild>("");
  const [view, setView] = useState<ViewVariant>("general");

  useEffect(() => {
    ReactGAEventWait({ category: "SignIn", action: "Opened" });

    const query = qs.parse(search);

    if (isLoginView(query.signUpVariant as string)) {
      setView(query.signUpVariant as ViewVariant);
    }
  }, []);

  useEffect(() => {
    if (user !== null) {
      const query = qs.parse(search);

      if (query.returnUrl) {
        history.replace(query.returnUrl as string);
      } else {
        closeModal();
      }
    }
  }, [user]);

  function closeModal() {
    ReactGAEventWait({ category: "SignIn", action: "Closed" });
    history.replace({
      pathname: realLocation.current.pathname,
      search: realLocation.current.search,
    });
  }

  const submitForm = handleSubmit(async (values) => {
    try {
      await login(values);
    } catch (e) {
      const error: AxiosError<{ errors: ServerError[] }> = e;
      const errors = error.response?.data?.errors;

      if (errors) {
        ReactGAEventWait({
          category: "SignIn",
          action: "Failed",
          label: errors[0].title,
        });

        setError(errors[0]);
      } else {
        setUnknownErrorNotification();
        throw error;
      }
    }
  });

  const setError = (e: ServerError) => {
    if (e.code === "dataseс_banned") {
      setErrorMessage(
        <span>
          There appears to be an issue with your account.{" "}
          <Link
            to={{
              pathname: "/support",
              search: `?topic=help-login&email=${watch("email")}`,
            }}
            css={css.errorLink}
          >
            Please contact our support team.
          </Link>
        </span>,
      );
      return;
    }

    setErrorMessage(e.title);
  };

  return (
    <Dialog
      open
      onClose={view !== "auth_required" ? closeModal : undefined}
      title="Log in"
      tier={Models.SubscriptionTier.Ivy}
    >
      <form data-test="sign-in-form" css={css.container}>
        <div>
          {view === "auth_required_warning" && (
            <Typography paragraph align="center">
              To continue browsing, log in <br /> or create a new account
            </Typography>
          )}
          {view === "auth_required" && (
            <Typography paragraph align="center">
              You've reached your content limit.
              <br />
              <br />
              To continue browsing, log in or <br />
              create an account at no charge.
            </Typography>
          )}

          <Controller
            as={
              <Input
                data-test="email-field"
                error={errors.email?.message}
                label="Email"
                type="email"
                disabled={formState.isSubmitting}
              />
            }
            defaultValue=""
            rules={{
              ...required(),
              ...email(),
            }}
            name="email"
            control={control}
          />

          <Controller
            as={
              <PasswordInput
                error={errors.password?.message}
                data-test="password-field"
                label="Password"
                disabled={formState.isSubmitting}
              />
            }
            defaultValue=""
            rules={required()}
            name="password"
            control={control}
          />
        </div>

        {errorMessage !== "" && (
          <SnackbarContentWrapper message={errorMessage} />
        )}

        <div css={css.footer}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            loading={formState.isSubmitting}
            data-test="submit-btn"
            onClick={submitForm}
            type="submit"
          >
            Log in
          </Button>
          <p css={css.footerLink}>
            Don't have an account?{" "}
            <Link
              to={{
                pathname: ROUTES.SIGNUP_FORM,
                search,
              }}
              data-test="sign-up-link"
            >
              Sign up
            </Link>
          </p>
          <p css={css.footerLink}>
            Password issues?{" "}
            <Link
              to={{
                pathname: ROUTES.FORGOT_PASSWORD_PAGE,
                search,
              }}
              data-test="forgot-password-link"
            >
              Retrieve or Reset
            </Link>
          </p>
          {view === "auth_required_warning" && (
            <>
              <ShadowDivider />
              <p css={css.footerLink} onClick={closeModal} className="g-link">
                Not now
              </p>
            </>
          )}
          {view === "auth_required" && (
            <>
              <Spacer />
              <Button
                variant="outlined"
                color="primary"
                size="large"
                link={{
                  pathname: "/",
                }}
                fullWidth
              >
                Take me back to the homepage
              </Button>
            </>
          )}
        </div>
      </form>
    </Dialog>
  );
};

export default LoginForm;
