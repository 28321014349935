import React from "react";
import SnackbarContent, {
  SnackbarContentProps,
} from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";

const styles1 = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  message: {
    alignItems: "center",
    display: "flex",
    marginRight: 30,
  },
}));

export const SnackbarContentWrapper: React.FC<SnackbarContentProps> = ({
  message,
  ...props
}) => {
  const classes = styles1();

  return (
    <SnackbarContent
      className={classes.error}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes?.message}>
          {message}
        </span>
      }
      {...props}
    />
  );
};
